<template>
    <div class="cav">
        <el-card>
            <template #header>
                <span>核销管理</span>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-select v-model="merchantid" size="small" style="width: 100px;" @change="GetStore" placeholder="请选择商户" filterable v-if="ismerchant != 1" clearable>
                        <el-option v-for="item in merchants" :key="item" :label="item.Merchant" :value="item.ID"></el-option>
                    </el-select>
                    <el-select v-model="storeid" size="small" style="margin-right:20px;width: 100px;" :disabled='(merchantid||ismerchant==1)?false:true' clearable>
                        <el-option v-for="item in stores" :key="item" :label="item.Store" :value="item.ID"></el-option>
                    </el-select>
                    <el-select v-model="platformid" style="width: 100px;" size="small" clearable placeholder="请选择营销平台">
                        <el-option label="美团" value="1"></el-option>
                        <el-option label="抖音" value="2"></el-option>
                        <el-option label="其他" value="3"></el-option>
                    </el-select>
                    <el-input v-model="keyword" size="small" placeholder="请输入核销验证码/核销码名称/客户联系手机" style="width:350px;margin-right:10px;"></el-input>
                    <el-button @click="init" type="primary" size="small">查询</el-button>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0;"
                                v-loading="load"
                                size="small"
                                @selection-change="CheckSelection"
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中...">
                    <el-table-column label="商户名称" prop="Merchant"></el-table-column>
                    <el-table-column label="核销店铺" prop="Store"></el-table-column>
                    <el-table-column label="核销码名称" prop="CodeTitle"></el-table-column>
                    <el-table-column label="核销码验证码" prop="CAVCode"></el-table-column>
                    <el-table-column label="营销平台" width="100px" align="center">
                        <template #default='scope'>
                            <span v-if="scope.row.CodeType == 1">美团</span>
                            <span v-if="scope.row.CodeType == 2">抖音</span>
                            <span v-if="scope.row.CodeType == 3">其他</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column label="联系电话" prop="Mobile" width="150px" align="center"></el-table-column>
                    
                    <el-table-column label="核销时间" align="center">
                        <template #default='scope'>
                            {{formatDate(scope.row.AddDTime)}}
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
</template>
<script>
import Cookies from 'js-cookie'

import constant from "@/constant"
export default {
    data(){
        return {
            stores:[],
            merchants:[],
            keyword:'',
            merchantid:'',
            storeid:'',
            tabledata:[],
            platformid:'',
        }
    },
    methods:{
        GetStore(merchantid){
            console.log(merchantid);
            this.axios.get(constant.allstore,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    merchantid:merchantid
                }
            }).then((response)=>{
                console.log(response.data);
                this.stores = response.data;
            });
        },
        formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
        init(){
            this.load = true;
            this.axios.get(constant.cavlist,{
                headers:{
                   "content-type":"application/json"
                },
                params:{
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    
                    platformid:this.platformid,
                    
                    storeid:this.storeid,
                    merchantid:this.merchantid,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    
                    keyword:this.keyword,
                    
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
                this.load = false;
            });
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.opid = Cookies.get("id");
        this.role = Cookies.get("role");
        this.axios.get(constant.allmerchant,{
            headers:{
                "content-type":"application/json"
            }
        }).then((response)=>{
            console.log(response.data);
            this.merchants = response.data;
        });
        this.init();
    }
}
</script>
<style scoped>
.content-header .el-select {
    margin-right: 20px;
}
.content-header .el-input-number {
    margin-top: 10px;
    margin-right: 10px;
    width: 150px;
}
.content-header .el-button {
    margin-top: 10px;
}
</style>